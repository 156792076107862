<template>
    <div>
        <v-container class="py-10">
            <v-row>
                <v-col md="12" class="d-flex pl-5">
                    <v-btn rounded color="green" depressed class="white--text" @click="$router.push(`/addQuizLms`)">
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("Add Quiz") }}
                    </v-btn>
                </v-col>
                <v-col md="3">
                    <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date"
                        v-model="filters.from">
                    </v-text-field>
                </v-col>
                <v-col md="3">
                    <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date"
                        v-model="filters.to">
                    </v-text-field>
                </v-col>
                <v-col md="3">
                    <AutoCompleteField end-point="/lms/quiz" :label="$t('Quiz Name')" v-model="filters.id"/>
                </v-col>
                <v-col md="3">
                    <AutoCompleteField end-point="/user/auto-complete" :label="$t('Quiz Creator')" v-model="filters.user_id"/>
                </v-col>
                <v-col md="3" class="mb-3 py-0">
                    <v-btn color="green" dark block @click="fetchData(1)">{{ $t('search') }}</v-btn>
                </v-col>
                <v-col md="3" class="mb-3 py-0">
                    <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
                </v-col>
                <v-col md="3" class="mb-3 py-0">
                    <ExportExcel end-point="/lms/quiz/export" :params="filters"/>
                </v-col>

                <v-col md="12">
                    <app-base-table :tableOptions="tableOptions" @re-fetch-data="fetchData"
                        @re-fetch-paginated-data="fetchData($event)" :enableDelete="false">
                        <template slot="editFeature" slot-scope="{ row }">
                            <deleteItem path="lms/quiz" :id="row.id" @record-deleted="fetchData(1)"></deleteItem>
                            <v-icon color="blue" @click="$router.push(`/editQuizLms/${row.id}`)">mdi-pen</v-icon>
                        </template>
                    </app-base-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import deleteItem from "../../../components/core/delete.vue";
import ExportExcel from "../../../components/core/ExportExcel.vue";
import AutoCompleteField from "../../../components/core/AutoCompleteField.vue";

import axios from "axios";
export default {
    components: { deleteItem, ExportExcel,AutoCompleteField },
    data() {
        return {
            tableOptions: {
                tableData: [],
                tableHeaders: ["id", "Quiz Name", "Quiz Creator", "Activate Date", "Attempt Numbers", "settings"],
                accessTableData: [["id"], ["name"], ["user"], ["activate_date"], ["repeat_number"]],
                loading: false,
                tableMeta: {
                    total: 10,
                    page: 1,
                },
            },
            filters: {
                from: null,
                to: null,
                id: null,
                user_id: null,
            },
        };
    },
    computed: {
        ...mapGetters({
            units: "subjects/getUnits",
        }),
    },
    methods: {
        ...mapActions({
            fetchUnits: "subjects/fetchUnits",
        }),
        async fetchData(page) {
            this.tableOptions.loading = true;
            const filterTemp = {};
            Object.keys(this.filters).forEach((key) => {
                if (this.filters[key]) filterTemp[key] = this.filters[key];
            });
            let params = {
                size: 10,
                page: page,
                ...filterTemp,
            };
            const response = await axios.get(`/lms/quiz`, { params });
            if (response.status == 200) {
                console.log(response.data.data);
                this.tableOptions.tableData = response.data.data,
                    this.tableOptions.tableMeta.total = response.data.meta.total;
                this.tableOptions.tableMeta.page = response.data.meta.current_page;
            }
            this.tableOptions.loading = false;
        },
        clearFilter() {
      this.filters.user_id = undefined;
      this.filters.id = undefined;
      this.filters.from = undefined;
      this.filters.to = undefined;
      this.fetchData(1);
    },
    },
    created() {
        this.fetchData(1);
    },
};
</script>
